import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { name as projectName } from '../../package.json';
import { version } from '../../package.json';

function initSentry() {
	Sentry.init({
		dsn: process.env.VUE_APP_SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],
		environment: process.env.NODE_ENV,
		release: `${projectName}@${version}`,
		// We recommend adjusting this value in production, or using tracesSampler for finer control.
		// Values vary from 0.0(0%) to 1.0(100%)
		tracesSampleRate: 1.0,
	});
}
/* Sentry only send debug, info, warn and error when a exception is raised */
class SentryLogger {
	constructor() {
		initSentry();
	}

	debug(m: string) {
		Sentry.captureMessage(m, Sentry.Severity.Debug);
		console.debug(m);
	}

	info(m: string) {
		Sentry.captureMessage(m, Sentry.Severity.Info);
		console.info(m);
	}

	warn(m: string) {
		Sentry.captureMessage(m, Sentry.Severity.Warning);
		console.warn(m);
	}

	error(m: string) {
		Sentry.captureMessage(m, Sentry.Severity.Error);
		console.error(m);
	}

	exception(e: Error | DOMException) {
		Sentry.captureException(e);
		console.error(JSON.stringify(e));
	}
}

export default SentryLogger;
