import { io, Socket } from 'socket.io-client';
import Peer from 'peerjs';
import SentryLogger from '@/utils/SentryLogger';
import { createWCRoom } from '@/services/room';
import { serverUrl, peerServerUrl, peerServerPort } from '@/utils/envs';

/*
 * WCCamera
 * Attributes:
 * - hang-up: true | false; When true, the camera will hang up
 * - room-id: string; The room id
 * - disable-lobby: true | false, default: false; When true the lobby will be disabled
 * - toggle-pip: true | false, default: false; When true the picture in picture will be enabled
 *
 * Events Emitted:
 * - telepresence-started: When the telepresence session has started, after lobby disappear
 * - telepresence-stopped: When the telepresence session has stopped, lobby is shown again
 * - pip-status: When the Picture in Picture status changes. Payload: { details.enabled: true | false }
 */

interface IRoomDom {
	[key: string]: any;
}
interface IPeers {
	[key: string]: Peer.MediaConnection | undefined;
}
interface IRoomParameter {
	id?: string;
	videoInputDeviceName?: string;
	streamVideo?: string;
	streamAudio?: string;
}
interface VideoPIP extends HTMLVideoElement {
	requestPictureInPicture(): Promise<any>;
}
interface DocPIP extends Document {
	exitPictureInPicture(): Promise<void>;
	pictureInPictureElement: HTMLVideoElement;
	pictureInPictureEnabled: boolean;
}

const template = document.createElement('template');
template.innerHTML = `
<div id="lobby">
	<div class="input-align">
		<label for="username">Apelido</label>
		<input name="username" type="text" maxlength="64" placeholder="Human">
	</div>
	<button id="start-telepresence-btn">Iniciar chamada</button>
</div>
<div id="camera" class="hidden">
	<div id="no-camera">
		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="animation-delay: 0s; animation-play-state: running; display: block; margin: auto; shape-rendering: auto;" width="150px" height="150px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
			<path d="M15 50A35 35 0 0 0 85 50A35 39 0 0 1 15 50" fill="#1e87f0" stroke="none" style=" animation-delay: 0s; animation-play-state: running;">
	  			<animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 52;360 50 52" style=" animation-delay: 0s; animation-play-state: running;"></animateTransform>
			</path>
		</svg>
	</div>
	<div id="no-internet" class="hidden">
		<h1> Sem conexão, verifique sua rede </h1>
		<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="#000000"><polygon points="16,6.82 17.59,8.41 19,7 15,3 11,7 12.41,8.41 14,6.82 14,11.17 16,13.17"/><polygon points="1.39,4.22 8,10.83 8,17.18 6.41,15.59 5,17 9,21 13,17 11.59,15.59 10,17.18 10,12.83 19.78,22.61 21.19,21.19 2.81,2.81"/></svg>
	</div>
	<video id="camera-video" class="hidden" playsinline></video>
	<span id="camera-info" class="hidden"></span>
</div>
<style>
	#lobby {
		display: grid;
		align-content: center;
		justify-content: center;
		margin: auto;
		height: inherit;
		width: inherit;
		min-width: inherit;
		min-height: inherit;
		max-width: inherit;
		max-height: inherit;
		background-color: #333;
	}
	#lobby label {
		color: #ddd;
	}
	#lobby button {
		border-radius: 5px;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding: 0 40px;
		background-color: #1e87f0;
		color: #fff;
		border: 1px solid transparent;
		font: inherit;
		-webkit-appearance: none;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		transition: .1s ease-in-out;
		cursor: pointer;
		margin: 15px 0px;
		height: 40px;
		justify-self: center;
	}
	.input-align {
		display: block;
		text-align: left;
	}
	.input-align > label {
		color: #333;
		font-size: .875rem;
		display: block;
	}
	.input-align > input {
		border-radius: 5px;
		height: 40px;
		vertical-align: middle;
		display: block;
		padding: 0 10px;
		background: #fff;
		color: #666;
		border: 1px solid #e5e5e5;
		transition: .2s ease-in-out;
		overflow: visible;
		-webkit-appearance: none;
		box-sizing: border-box;
		margin: 0;
		font: inherit;
		width: 100%;
	}
	.input-align > input:focus {
		outline: 0;
		background-color: #fff;
		color: #666;
		border-color: #1e87f0;
	}

	#camera {
		height: inherit;
		width: inherit;
		min-height: inherit;
		max-height: inherit;
		min-width: inherit;
		max-width: inherit;
		background-color: #333;
		overflow: hidden;
	}

	#no-camera, #no-internet {
		background-color: #333;
		display: flex;
		position: relative;
		height: inherit;
		width: inherit;
		min-height: inherit;
		max-height: inherit;
		min-width: inherit;
		max-width: inherit;
		align-items: center;
		flex-flow: column;
		justify-content: center;
	}

	#no-internet > svg {
		width: 100px;
		height: 100px;
		fill: #ddd;
	}
	#no-internet > h1 {
		color: #ddd;
	}

	video {
		display: block;
		object-fit: contain;
		width: 100%;
		height: 100%;
	}

	.video-text {
		color: #aaa;
		font-size: small;
		right: 0px;
		padding: 5px;
		position: absolute;
		bottom: 0px;
	}

	.hidden {
		display: none !important;
	}
</style>
`;

class WCCamera extends HTMLElement {
	sentryLogger = new SentryLogger();

	socket!: Socket;

	myPeer!: Peer;

	peers: IPeers = {};

	currentStreamerId: string = '';

	videoTimeCounter: number = 0;

	shadowDom: IRoomDom = {
		camera: null,
		cameraVideo: null,
		cameraInfo: null,
		noCamera: null,
		noInternet: null,
		lobby: null,
		startTelepresenceBtn: null,
	};

	roomParameters: IRoomParameter = {
		id: '',
		videoInputDeviceName: 'default',
		streamVideo: 'true',
		streamAudio: 'true',
	};

	telepresenceHasStarted = false;

	static get observedAttributes() {
		return ['hang-up', 'room-id', 'disable-lobby', 'toggle-pip'];
	}

	constructor() {
		super();
		this.attachShadow({ mode: 'open' });
		this.shadowRoot!.appendChild(template.content.cloneNode(true));
	}

	connectedCallback() {
		this.initShadowRootElements();
		this.initShadowRootEvents();
	}

	attributeChangedCallback(attrName: string, oldVal: string, newVal: string) {
		if (attrName === 'hang-up' && newVal === 'true') {
			this.hangup();
		}
		if (attrName === 'room-id' && newVal !== oldVal && newVal) {
			this.sentryLogger.info('Room ID changed, restarting camera component.');
			this.hangup();

			if (this.getAttribute('disable-lobby') === 'true' && !this.telepresenceHasStarted) {
				this.initTelepresence();
			}
		}
		if (attrName === 'disable-lobby' && newVal === 'true' && !this.telepresenceHasStarted) {
			this.initTelepresence();
		}
		if (attrName === 'toggle-pip' && newVal === 'true' && this.telepresenceHasStarted) {
			this.togglePictureInPicture(true);
		} else if (attrName === 'toggle-pip' && newVal === 'false') {
			this.togglePictureInPicture(false);
		}
	}

	initShadowRootElements() {
		this.shadowDom.camera = this.shadowRoot!.getElementById('camera');
		this.shadowDom.cameraVideo = this.shadowRoot!.getElementById('camera-video') as VideoPIP;
		this.shadowDom.cameraInfo = this.shadowRoot!.getElementById('camera-info');
		this.shadowDom.noCamera = this.shadowRoot!.getElementById('no-camera');
		this.shadowDom.noInternet = this.shadowRoot!.getElementById('no-internet');
		this.shadowDom.lobby = this.shadowRoot!.getElementById('lobby');
		this.shadowDom.startTelepresenceBtn = this.shadowRoot!.getElementById('start-telepresence-btn');
	}

	initShadowRootEvents() {
		this.shadowDom.startTelepresenceBtn!.addEventListener('click', this.initTelepresence.bind(this));
		this.shadowDom.cameraVideo.addEventListener('enterpictureinpicture', this.enterPictureInPicture.bind(this));
		this.shadowDom.cameraVideo.addEventListener('leavepictureinpicture', this.leavePictureInPicture.bind(this));
		this.shadowDom.cameraVideo.addEventListener('timeupdate', this.onStreamUpdate.bind(this));
	}

	async loadTelepresence() {
		const id = this.getAttribute('room-id') ?? undefined;
		if (id) {
			this.roomParameters = { ...this.roomParameters, ...(await this.getRoomParameters()) };
			this.initPeerAndSocketEvents();
		} else {
			setTimeout(this.loadTelepresence.bind(this), 250);
		}
	}

	initTelepresence() {
		this.loadTelepresence();
		this.toggleLobby(true);
		this.onStreamLoad();
	}

	enterPictureInPicture() {
		this.shadowRoot!.dispatchEvent(
			new CustomEvent('pip-status', {
				bubbles: true,
				composed: true,
				detail: { enabled: true },
			})
		);
	}

	leavePictureInPicture() {
		this.shadowRoot!.dispatchEvent(
			new CustomEvent('pip-status', {
				bubbles: true,
				composed: true,
				detail: { enabled: false },
			})
		);
		this.shadowDom.cameraVideo.load();
	}

	onStreamLoad() {
		const play = async () => {
			try {
				await this.shadowDom.cameraVideo.play();
				console.log('Automatic stream playback started.');
			} catch (e: any) {
				console.error('Automatic stream playback failed', e);
				setTimeout(play, 250);
			}
		};
		play();
		this.shadowDom.cameraInfo.className = 'video-text';
		this.shadowDom.cameraInfo.append(
			`${this.shadowDom.cameraVideo.videoWidth}x${this.shadowDom.cameraVideo.videoHeight}`
		);
	}

	onStreamUpdate() {
		let counterUpdatedSymbol = this.videoTimeCounter % 1 === 0 ? '*' : '';
		const text = `${counterUpdatedSymbol}${this.shadowDom.cameraVideo.videoWidth}x${this.shadowDom.cameraVideo.videoHeight}${counterUpdatedSymbol}`;
		this.shadowDom.cameraInfo.textContent = text;
		this.videoTimeCounter += 1;
		if (this.videoTimeCounter > 100) {
			this.videoTimeCounter = 0;
		}
	}

	async togglePictureInPicture(status: boolean) {
		try {
			const doc = document as DocPIP;
			if (!('pictureInPictureEnabled' in doc)) {
				console.log('Picture-in-Picture Web API is not supported');
				return;
			}
			if (status && doc.pictureInPictureElement) {
				return;
			}
			if (!status && !doc.pictureInPictureElement) {
				return;
			}
			if (!status && doc.pictureInPictureElement) {
				await doc.exitPictureInPicture();
				return;
			}
			if (status && !doc.pictureInPictureElement) {
				this.shadowDom?.cameraVideo?.requestPictureInPicture();
			}
		} catch (error) {
			this.sentryLogger.exception(error as Error);
			this.shadowRoot!.dispatchEvent(
				new CustomEvent('pip-status', {
					bubbles: true,
					composed: true,
					detail: { enabled: false },
				})
			);
		}
	}

	async getRoomParameters() {
		const id = this.getAttribute('room-id') ?? undefined;
		const room = await createWCRoom({ id });
		if (room.success === false) {
			this.sentryLogger.error(room.message);
			return {};
		}
		return room;
	}

	toggleLobby(disabled: boolean) {
		if (disabled) {
			this.shadowDom.lobby?.classList.add('hidden');
			this.shadowDom.camera?.classList.remove('hidden');
		} else {
			this.shadowDom.lobby?.classList.remove('hidden');
			this.shadowDom.camera?.classList.add('hidden');
		}
	}

	initPeerAndSocketEvents() {
		this.sentryLogger.info('Starting telepresence peer and sockets');
		this.telepresenceHasStarted = true;
		this.shadowRoot!.dispatchEvent(
			new CustomEvent('telepresence-started', {
				bubbles: true,
				composed: true,
			})
		);
		this.myPeer = new Peer(undefined, {
			host: peerServerUrl,
			port: peerServerPort,
			path: '/myapp/',
			config: {
				iceServers: [
					{
						urls: ['stun:us-turn8.xirsys.com'],
					},
					{
						username:
							'1gzEdlKRFFrrp4PoehmL-L6-aVc16tX5OLY7Ia3rjPYx_owZK5D1XLeTMRaki9-4AAAAAGGEES53ZWJydGM0cm9iaW9z',
						credential: '6b24cb7a-3d90-11ec-b36b-0242ac140004',
						urls: [
							'turn:us-turn8.xirsys.com:80?transport=udp',
							'turn:us-turn8.xirsys.com:3478?transport=udp',
							'turn:us-turn8.xirsys.com:80?transport=tcp',
							'turn:us-turn8.xirsys.com:3478?transport=tcp',
							'turns:us-turn8.xirsys.com:443?transport=tcp',
							'turns:us-turn8.xirsys.com:5349?transport=tcp',
						],
					},
				],
			},
		});
		this.socket = io(serverUrl, { secure: true, reconnection: true, rejectUnauthorized: false });
		this.myPeer.on('open', (id) => this.handlePeerOpen(id));
		this.myPeer.on('call', (call) => this.handlePeerIncomingCall(call));
		this.myPeer.on('error', (err) => this.sentryLogger.error(`this.myPeer error type=${err.type}`));
		this.socket.on('connect', () => this.handleSocketConnect());

		this.socket.on('connect_error', (err) => this.sentryLogger.error(`Socket connect_error due to ${err.message}`));
		this.socket.on('disconnect', () => this.handleDisconnection());
		this.socket.io.on('reconnect', () => this.handleReconnection());
		this.socket.io.on('reconnect_error', (err) => this.sentryLogger.error(`Reconnect socket error: ${err}`));

		this.socket.on('user-connected', (userId) => this.callPeer(userId));
		this.socket.on('user-disconnected', (userId) => this.disconnectPeer(userId));
	}

	handlePeerOpen(id: string) {
		this.socket.emit('join-room', this.roomParameters.id, id);
		this.sentryLogger.info(`New User for room ${this.roomParameters.id}, platform=${window.navigator.userAgent}`);
		this.sentryLogger.info(`current user id is ${this.myPeer.id}`);
	}

	handlePeerIncomingCall(call: Peer.MediaConnection) {
		this.sentryLogger.info(`A remote user with id ${call.peer} is calling`);
		call.answer();
		if (call.metadata === 'user-no-stream') {
			return;
		}
		let hasAlreadyBeenCalled = false;
		call.on('stream', (userVideoStream: MediaStream) => {
			if (hasAlreadyBeenCalled) {
				// Ignore the second+ stream. It comes duplicated because of different sources(audio, video...)
				return;
			}
			hasAlreadyBeenCalled = true;
			this.sentryLogger.info(`Adding Video Stream from user id=${call.peer}`);
			this.currentStreamerId = call.peer;
			this.shadowDom.cameraVideo.srcObject = userVideoStream;
			this.shadowDom.noCamera.classList.add('hidden');
			this.shadowDom.noInternet.classList.add('hidden');
			this.shadowDom.cameraVideo.classList.remove('hidden');
			this.shadowDom.cameraInfo.classList.remove('hidden');
		});
	}

	handleSocketConnect() {
		if (!this.currentStreamerId) {
			this.shadowDom.cameraVideo.classList.add('hidden');
			this.shadowDom.cameraInfo.classList.add('hidden');
			this.shadowDom.noInternet.classList.add('hidden');
			this.shadowDom.noCamera.classList.remove('hidden');
		}
	}

	handleDisconnection() {
		this.sentryLogger.info('Socket disconnected, check the internet connection');

		this.shadowDom.cameraVideo.pause();
		this.shadowDom.cameraVideo.removeAttribute('srcObject');
		this.shadowDom.cameraVideo.classList.add('hidden');
		this.shadowDom.cameraInfo.classList.add('hidden');
		this.shadowDom.noInternet.classList.remove('hidden');
		this.shadowDom.noCamera.classList.add('hidden');
		this.myPeer?.disconnect();
	}

	handleReconnection() {
		this.sentryLogger.info('Reconnecting stream');
		this.myPeer?.reconnect();
	}

	callPeer(userId: string) {
		this.sentryLogger.info(`Connecting to peer with userId = ${userId}`);
		const emptyStream = this.getEmptyMediaStream();
		const call = this.myPeer.call(userId, emptyStream, { metadata: 'user-no-stream' });
		let hasAlreadyBeenCalled = false;
		call.on('stream', (userVideoStream: MediaStream) => {
			if (hasAlreadyBeenCalled) {
				// Ignore the second+ stream. It comes duplicated because of different sources(audio, video...)
				return;
			}
			hasAlreadyBeenCalled = true;
			this.sentryLogger.info(`Adding Video Stream from user id=${userId}`);
			this.currentStreamerId = userId;
			this.shadowDom.cameraVideo.srcObject = userVideoStream;
			this.shadowDom.noCamera.classList.add('hidden');
			this.shadowDom.noInternet.classList.add('hidden');
			this.shadowDom.cameraVideo.classList.remove('hidden');
			this.shadowDom.cameraInfo.classList.remove('hidden');
		});

		call.on('close', () => {
			this.sentryLogger.debug('Closing user connection');
		});

		this.peers[userId] = call;
	}

	disconnectPeer(userId: string) {
		this.sentryLogger.info(`User disconnected : ${userId}`);

		if (this.currentStreamerId === userId) {
			this.currentStreamerId = '';
			this.shadowDom.cameraVideo.pause();
			this.shadowDom.cameraVideo.removeAttribute('srcObject');
			this.shadowDom.cameraVideo.classList.add('hidden');
			this.shadowDom.cameraInfo.classList.add('hidden');
			this.shadowDom.noInternet.classList.add('hidden');
			this.shadowDom.noCamera.classList.remove('hidden');
		}
		if (userId && this.peers[userId]) {
			this.peers[userId]?.close();
			delete this.peers[userId];
		}
	}

	getEmptyMediaStream() {
		const createEmptyAudioTrack = () => {
			interface IMediaStreamAudioSourceNode extends AudioNode {
				stream: MediaStream;
			}
			const ctx = new AudioContext();
			const oscillator = ctx.createOscillator();
			const dst = oscillator.connect(ctx.createMediaStreamDestination());
			oscillator.start();
			const track = (dst as IMediaStreamAudioSourceNode).stream.getAudioTracks()[0];
			return Object.assign(track, { enabled: false });
		};

		const createEmptyVideoTrack = ({ width, height }: { width: number; height: number }) => {
			interface IHTMLCanvasElement extends HTMLCanvasElement {
				captureStream: () => MediaStream;
			}
			const canvas = Object.assign(document.createElement('canvas'), { width, height });
			canvas.getContext('2d')!.fillRect(0, 0, width, height);
			const stream = (canvas as IHTMLCanvasElement).captureStream();
			const track = stream.getVideoTracks()[0];
			return Object.assign(track, { enabled: false });
		};

		const audioTrack = createEmptyAudioTrack();
		const videoTrack = createEmptyVideoTrack({ width: 640, height: 480 });
		const mediaStream = new MediaStream([audioTrack, videoTrack]);
		return mediaStream;
	}

	disconnectedCallback() {
		this.hangup();
		this.stopShadowDomEvents();
	}

	hangup() {
		this.sentryLogger.info('Hangup event called, stopping camera component.');
		this.telepresenceHasStarted = false;
		this.shadowRoot!.dispatchEvent(
			new CustomEvent('telepresence-stopped', {
				bubbles: true,
				composed: true,
			})
		);
		this.currentStreamerId = '';
		this.shadowDom.cameraVideo.pause();
		this.shadowDom.cameraVideo.removeAttribute('srcObject');
		this.shadowDom.cameraVideo.classList.add('hidden');
		this.shadowDom.cameraInfo.classList.add('hidden');
		this.shadowDom.noInternet.classList.add('hidden');
		this.shadowDom.noCamera.classList.remove('hidden');
		this.myPeer?.disconnect();
		this.socket?.disconnect();
		this.toggleLobby(false);
	}

	stopShadowDomEvents() {
		this.shadowDom.startTelepresenceBtn.removeEventListener('click', this.initTelepresence);
		this.shadowDom.cameraVideo.removeEventListener('enterpictureinpicture', this.enterPictureInPicture);
		this.shadowDom.cameraVideo.removeEventListener('leavepictureinpicture', this.leavePictureInPicture);
		this.shadowDom.cameraVideo.removeEventListener('loadedmetadata', this.onStreamLoad);
		this.shadowDom.cameraVideo.removeEventListener('timeupdate', this.onStreamUpdate);
	}
}

customElements.define('wc-camera', WCCamera);
