let serverUrl: string = '';
let peerServerUrl: string = '';
let peerServerPort: number = 0;

if (process.env.NODE_ENV === 'production') {
	serverUrl = process.env.VUE_APP_CLOUD_SERVER_URL;
	peerServerUrl = process.env.VUE_APP_CLOUD_PEER_SERVER_URL;
	peerServerPort = Number(process.env.VUE_APP_CLOUD_PEER_SERVER_PORT);
} else {
	serverUrl = process.env.VUE_APP_LOCAL_SERVER_URL;
	peerServerUrl = process.env.VUE_APP_LOCAL_PEER_SERVER_URL;
	peerServerPort = Number(process.env.VUE_APP_LOCAL_PEER_SERVER_PORT);
}

export { serverUrl, peerServerUrl, peerServerPort };
