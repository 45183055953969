import { serverUrl } from '@/utils/envs';

const urls = {
	room: {
		createRobotRoom: `${serverUrl}/api/room-robot`,
		createClientRoom: `${serverUrl}/api/room-client`,
		createWCRoom: `${serverUrl}/api/room-wc`,
	},
};

export { urls };
