async function post(url: string, data: any): Promise<Response> {
	return await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
			Accept: 'application/json; charset=utf-8',
		},
		body: JSON.stringify(data),
	});
}

async function get(url: string): Promise<Response> {
	return await fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
			Accept: 'application/json; charset=utf-8',
		},
	});
}

export { post, get };
