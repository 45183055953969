import { urls } from '@/utils/urls';
import { post } from '@/utils/request';

interface IRobotRoomRequest {
	id?: string;
	videoInputLabel?: string;
	audioInputLabel?: string;
	streamAudio?: string;
}

interface IClientRoomRequest {
	id?: string;
	action?: string;
	videoInputDeviceName?: string;
	streamVideo?: string;
	streamAudio?: string;
}

interface IWCRoomRequest {
	id?: string;
	action?: string;
	videoInputDeviceName?: string;
	streamVideo?: string;
	streamAudio?: string;
}

async function createRobotRoom(params: IRobotRoomRequest = {}) {
	const room = await post(urls.room.createRobotRoom, params);
	return room.json();
}

async function createClientRoom(params: IClientRoomRequest = {}) {
	const room = await post(urls.room.createClientRoom, params);
	return room.json();
}

async function createWCRoom(params: IWCRoomRequest = {}) {
	const room = await post(urls.room.createWCRoom, params);
	return room.json();
}

export { createRobotRoom, createClientRoom, createWCRoom };
